import { useState } from "react";

import { MapMarkerDisplayTypeEnum } from "custom-types/StoreLocator";

type Props = {
  logoImage: string;
  alt: string;
  displayType: MapMarkerDisplayTypeEnum;
};

const MapMarkerImage = ({ logoImage, alt, displayType }: Props) => {
  const [useBackupImage, setUseBackupImage] = useState(false);

  if (
    !useBackupImage &&
    [
      MapMarkerDisplayTypeEnum.ProminentImageContainer,
      MapMarkerDisplayTypeEnum.ImageContainer,
    ].includes(displayType)
  ) {
    const imgSize =
      displayType === MapMarkerDisplayTypeEnum.ProminentImageContainer
        ? 60
        : 44;
    const optimizedSrc = `${logoImage}?auto=compress,format&w=${imgSize}`;
    return (
      <img
        alt={alt}
        srcSet={`${DPR_SUPPORT.map((dpr) =>
          createDprSrc(optimizedSrc, dpr),
        ).join(",")}`}
        src={optimizedSrc}
        className="map-marker__image w-full absolute rounded-full bg-white lazyload"
        onError={() => {
          setUseBackupImage(true);
        }}
      />
    );
  }

  return null;
};

const DPR_SUPPORT = [1, 2, 3];

const createDprSrc = (src: string, dpr: number) => `${src}&dpr=${dpr} ${dpr}x`;

export default MapMarkerImage;
