import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { useInView } from "react-intersection-observer";

import getStoreLocatorResults from "api/requests/consumerApi/getStoreLocatorResults";
import { useSelector } from "redux/hooks";
import { getCoordinates } from "redux/selectors/location";
import { getUserHistoryItems } from "utils/userHistory";

import { DispensaryCardDispensary } from "./RecentlyViewedCarousel";

const RecentlyViewedCarousel = dynamic(
  () => import("./RecentlyViewedCarousel"),
  { ssr: false },
);

const RecentlyViewedCarouselWithData: React.FC = () => {
  const coordinates = useSelector(getCoordinates);
  const [recentlyViewedStores, setRecentlyViewedStores] = useState<
    DispensaryCardDispensary[]
  >([]);
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const getRecentlyViewed = async () => {
    const storedDispensaries = await getUserHistoryItems("dispensary", {}, 5);
    const recentlyViewedIds = storedDispensaries?.length
      ? storedDispensaries.map((entry) => entry.resourceId).filter((id) => id)
      : [];
    if (recentlyViewedIds.length) {
      const { data } = await getStoreLocatorResults({
        disableGeoFilter: true,
        ids: recentlyViewedIds,
        mapData: {
          center: {
            lat: coordinates?.lat,
            lon: coordinates?.lon,
          },
        },
      });

      const stores = [
        ...data.organicStores,
        ...data.sponsoredStores,
        ...data.spotlightStores,
      ];

      if (stores.length) {
        setRecentlyViewedStores(
          stores
            .map((store) => ({
              currentStatuses: {
                delivery: null,
                pickup: null,
                store: store.openStatus,
              },
              distanceMi: store.distanceMi,
              flags: store.configurations.preorderEnabled
                ? ["preorderConfigured"]
                : [],
              id: store.id,
              inStoreCartEnabled: store.configurations.inStoreCartEnabled,
              mainTag: store.mainTag,
              name: store.name,
              path: store.path,
              reviewCount: store.reviewCount,
              slug: store.slug,
              starRating: store.reviewRating,
              timeZone: store.timeZone,
            }))
            .sort(
              (a, b) =>
                recentlyViewedIds.indexOf(a.id) -
                recentlyViewedIds.indexOf(b.id),
            ),
        );
      }
    }
  };

  useEffect(() => {
    if (inView) {
      getRecentlyViewed();
    }
  }, [coordinates, inView]);

  return (
    <div ref={ref}>
      {recentlyViewedStores.length > 0 && (
        <div className="mt-xl">
          <RecentlyViewedCarousel stores={recentlyViewedStores} />
        </div>
      )}
    </div>
  );
};

export default RecentlyViewedCarouselWithData;
