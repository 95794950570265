import React from "react";
import classNames from "classnames";

import { FAQ } from "custom-types/Directus";
import { Location } from "custom-types/Location";
import { StoreSeo } from "custom-types/StoreLocator";

import DeliveryValidation from "../DeliveryValidation";

const DispensariesHeader = ({
  filtersOpen,
  seo,
  seoOverride,
  showDeliveryValidation,
  pageLocation,
}: {
  filtersOpen: boolean;
  seo: StoreSeo;
  seoOverride: FAQ | null;
  showDeliveryValidation: boolean;
  pageLocation: Location;
}) => {
  const h1Text =
    seoOverride?.h1_override ||
    seo.headline ||
    "Recreational & medical dispensaries near you";

  return (
    <div
      className={classNames("px-lg md:pt-lg width-full", {
        hidden: filtersOpen,
      })}
      data-testid="dispensaries-header"
    >
      <h1 className="heading--s font-bold">{h1Text}</h1>
      {showDeliveryValidation && (
        <DeliveryValidation
          pageLocation={pageLocation}
          onSuccessfulResult={() => {
            window.location.href = "/dispensaries";
          }}
        />
      )}
    </div>
  );
};

export default DispensariesHeader;
