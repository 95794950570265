"use client";

import React, { useEffect, useMemo, useState } from "react";
import { Transition } from "@headlessui/react";
import {
  APIProvider,
  ControlPosition,
  Map,
  MapControl,
  useMap,
} from "@vis.gl/react-google-maps";
import classNames from "classnames";
import dynamic from "next/dynamic";

import publicConfig from "config/public";
import { Action, Category } from "constants/events";
import { RetailType } from "custom-types/Store";
import { MapData, Store } from "custom-types/StoreLocator";
import { useEventTracker } from "hooks/useEventTracker";
import { getFittedBounds } from "utils/storeLocator/getFittedBounds";

import Geolocator from "./Geolocator";
import MapMarker from "./MapMarker";
import SearchButton from "./SearchButton";
import ZoomControls from "./ZoomControls";

const MapOverlays = dynamic(() => import("./MapOverlays/MapOverlays"), {
  ssr: false,
});

const {
  imgix: { publicUrl },
} = publicConfig;

export const MapComponent = ({
  retailType,
  stores,
  initMapData,
  setMapLoaded,
  mapLoaded,
}: {
  retailType: RetailType;
  stores: Store[];
  initMapData: MapData;
  setMapLoaded: (loaded: boolean) => void;
  mapLoaded: boolean;
}) => {
  const [showSearchButton, setShowSearchButton] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);
  useEffect(() => {
    setShouldRender(true);
  }, []);

  const map = useMap();
  const { publishEvent } = useEventTracker();

  useEffect(() => {
    if (map?.getDiv()) {
      getFittedBounds({
        mapData: initMapData,
        stores,
      }).then((fittedBounds) => {
        if (fittedBounds) {
          map?.fitBounds(fittedBounds);
        }
      });
    }
  }, [initMapData, mapLoaded, map?.getDiv()]);

  const defaultViewProps = useMemo(() => {
    if (initMapData.bounds) {
      return {
        defaultBounds: initMapData.bounds,
      };
    }

    if (initMapData.center && initMapData.zoom) {
      return {
        defaultCenter: {
          lat: initMapData.center.lat,
          lng: initMapData.center.lon,
        },
        defaultZoom: initMapData.zoom,
      };
    }

    return {};
  }, [initMapData]);

  return shouldRender ? (
    <Map
      {...defaultViewProps}
      mapId={publicConfig.storeLocatorMapId}
      onDragend={() => {
        setShowSearchButton(true);
        publishEvent({
          action: Action.click,
          category: Category.map,
          label: "map drag",
        });
      }}
      onZoomChanged={() => {
        if (mapLoaded) {
          setShowSearchButton(true);
        }
      }}
      disableDefaultUI
      gestureHandling="greedy"
      clickableIcons={false}
      onTilesLoaded={() => setMapLoaded(true)}
    >
      <MapControl position={ControlPosition.TOP_CENTER}>
        {showSearchButton && (
          <SearchButton clearSeachButton={() => setShowSearchButton(false)} />
        )}
      </MapControl>
      <MapControl position={ControlPosition.RIGHT_TOP}>
        <div className="m-md flex flex-col">
          <Geolocator retailType={retailType} />
          <ZoomControls setShowSearchButton={setShowSearchButton} />
        </div>
      </MapControl>
      {stores.map((store) =>
        store.mapMarkers.map((marker, i) => (
          <MapMarker
            key={`store-locator-marker-${store.id}-${i}`}
            store={store}
            mapMarker={marker}
          />
        )),
      )}
    </Map>
  ) : null;
};

const getPlaceholderImgURL = (size: "sm" | "md" | "lg") => {
  const w = { lg: 1025, md: 768, sm: 640 }[size];
  const h = (w * 9) / 16;
  return `${publicUrl}/web-web/finder/finder-map-placeholder.jpg?auto=compress,format&fit=crop&w=${w}&h=${h}&dpr=1`;
};

const MapPlaceholder = () => {
  return (
    <picture>
      <source media="(max-width:768px)" srcSet={getPlaceholderImgURL("sm")} />
      <source media="(max-width:1025px)" srcSet={getPlaceholderImgURL("md")} />
      <img
        src={getPlaceholderImgURL("lg")}
        className="object-cover object-left h-full w-full"
        alt="Loading map..."
      />
    </picture>
  );
};

const MapSection: React.FC<{
  initMapData: MapData;
  retailType: RetailType;
  spotlightStores: Store[];
  stores: Store[];
}> = ({ initMapData, retailType, spotlightStores, stores }) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  return (
    <div
      className={classNames(
        "fixed bg-white md:bottom-0",
        "md:left-[var(--stores-desktop-listing-width)] md:top-[var(--header-height)]",
        "inset-[var(--stores-collapsed-header-height)_0_var(--stores-mobile-bottom-touchpoint)_0]",
      )}
    >
      <Transition show={!mapLoaded}>
        <div className="absolute top w-full h-full transition duration-1000 data-[closed]:opacity-0">
          <MapPlaceholder />
        </div>
      </Transition>
      <div
        className={classNames(
          "h-full w-full transition delay-300 duration-1000",
          {
            "opacity-0": !mapLoaded,
          },
        )}
      >
        <APIProvider
          apiKey={publicConfig.googleMapsApiKey}
          libraries={["places"]}
        >
          <MapComponent
            retailType={retailType}
            stores={stores}
            initMapData={initMapData}
            setMapLoaded={setMapLoaded}
            mapLoaded={mapLoaded}
          />
        </APIProvider>
      </div>
      <MapOverlays spotlightStores={spotlightStores} />
    </div>
  );
};

export default MapSection;
