import { ReactNode, useState } from "react";
import classNames from "classnames";
import { useRouter } from "next/router";
import pluralize from "pluralize";
import { ParsedQs } from "qs";

import { Action, Category } from "constants/events";
import { listingSortDisplayStrings } from "constants/finder/filters";
import { Query } from "custom-types/Query";
import { useEventTracker } from "hooks/useEventTracker";
import parseNextRouterAsPath from "utils/parseNextRouterAsPath";

import FilterShortcut from "components/botanic/FilterShortcut";
import DoctorAd from "components/DoctorAd";
import { FilterButton } from "components/FilteredGrid/FilterButton";
import SwitchToMapButton from "components/Finder/ListingsWrapper/SwitchToMapButton";
import CloseIcon from "components/Icons/x.svg";
import AppliedChips from "components/UnifiedShop/AppliedChips";
import DynamicFilters from "components/UnifiedShop/DynamicFilters";
import { AvailableFilter } from "components/UnifiedShop/DynamicFilters/types";
import { getUpdatedFilterHref } from "components/UnifiedShop/utils/filterUtils";

import { useFilterQueryParams } from "../../MenuFilters/v2/hooks";
import SortDropdown from "../SortDropdown/SortDropdown";

const StoreFilters = ({
  children,
  availableFilters,
  filtersOpen,
  setFiltersOpen,
  toggleFilterList,
  shallowRouting,
  showMapButton,
  totalCount,
  availableSorts,
  retailType,
}: {
  children: ReactNode;
  availableFilters: AvailableFilter[];
  filtersOpen: boolean;
  setFiltersOpen: (open: boolean) => void;
  toggleFilterList: (open?: boolean) => void;
  shallowRouting?: boolean;
  loading?: boolean;
  showMapButton: boolean;
  totalCount: number;
  availableSorts: string[];
  retailType: string;
}) => {
  const [openMobileSectionKey, setOpenMobileSectionKey] = useState<string>();
  const { publishEvent } = useEventTracker();
  const {
    actions: { clearAllFilters },
  } = useFilterQueryParams(shallowRouting);
  const router = useRouter();
  const { asPath } = router;
  const { query } = parseNextRouterAsPath<ParsedQs>(asPath);
  const filtersApplied = query.filter as Query;
  const numberOfFiltersSelected = Object.entries(filtersApplied || {}).length;

  const onShortcutClick = (filterSectionKey?: string) => {
    setOpenMobileSectionKey(filterSectionKey);
    setFiltersOpen(true);
    toggleFilterList(true);
  };

  return (
    <>
      <div
        className={classNames(
          "flex flex-col h-full min-h-0 gap-sm md:gap-0 md:pt-lg",
          {
            hidden: filtersOpen,
          },
        )}
        data-testid={"filter-shortcut-view-container"}
      >
        <div
          data-testid="filter-container"
          className={classNames(
            "flex items-center whitespace-nowrap md:pb-sm md:mb-xs gap-2 overflow-x-auto overflow-y-hidden px-lg max-sm:no-scrollbar",
            { "flex-wrap": numberOfFiltersSelected },
          )}
        >
          <FilterButton
            filterCount={numberOfFiltersSelected}
            onClick={() => {
              toggleFilterList(true);
              setFiltersOpen(true);
            }}
            className="!text-default"
          />
          {availableFilters.length > 0 &&
            !filtersApplied &&
            availableFilters.map((filter) => (
              <FilterShortcut
                key={filter.name}
                className="!text-default"
                showChevron={filter.type !== "toggle"}
                text={filter.label}
                href={
                  filter.type === "toggle"
                    ? getUpdatedFilterHref(asPath, filter.name, "true")
                    : undefined
                }
                onClick={() => {
                  onShortcutClick(filter.name);
                  publishEvent({
                    action: "click",
                    category: "finder filter",
                    label: filter.label,
                  });
                }}
              />
            ))}
          <AppliedChips availableFilters={availableFilters} />
        </div>
        <div className="flex justify-between items-start px-lg">
          <div className="flex items-start">
            <div
              className="flex-shrink-0 font-bold text-xs mr-xxl"
              data-testid="listing-result-count"
            >
              {pluralize("result", totalCount, true)}
            </div>
          </div>
          {totalCount > 0 && (
            <SortDropdown
              availableSorts={availableSorts}
              eventCategory={Category.finderSort}
              sortDisplayStrings={listingSortDisplayStrings}
              onToggleDropdown={() => !showMapButton && toggleFilterList(true)}
            />
          )}
        </div>
        <div
          className="flex-1 overflow-y-auto"
          onPointerDown={(e) => {
            e.stopPropagation();
          }}
        >
          {retailType === "clinic" && (
            <div className="px-lg">
              <DoctorAd />
            </div>
          )}
          {children}
        </div>
        <SwitchToMapButton
          showMapButton={showMapButton && !filtersOpen}
          onClick={() => {
            toggleFilterList(false);
            publishEvent({
              action: Action.click,
              category: Category.finder,
              label: "map view",
            });
          }}
        />
      </div>
      <div
        className={classNames("flex flex-col h-full min-h-0 bg-white", {
          hidden: !filtersOpen,
        })}
        data-testid={"filter-view-container"}
      >
        <div className="flex justify-between items-center p-3.5">
          <div className="title text-xs uppercase font-extrabold">Filters</div>
          <button
            aria-label="Close modal"
            className="close-button"
            onClick={() => {
              toggleFilterList();
              setFiltersOpen(false);
            }}
            data-testid="close-modal"
          >
            <CloseIcon width={22} height={22} />
          </button>
        </div>
        <div className="flex items-center whitespace-nowrap pb-sm mb-xs gap-1 no-scrollbar overflow-x-auto overflow-y-hidden flex-wrap px-lg">
          <AppliedChips availableFilters={availableFilters} />
        </div>
        <div
          className="flex-1 overflow-y-auto no-scrollbar"
          onPointerDown={(e) => {
            e.stopPropagation();
          }}
        >
          <DynamicFilters
            availableFilters={availableFilters}
            defaultOpenKey={openMobileSectionKey}
            sectionPadding="px-4"
          />
        </div>
        <div className="sticky bottom-0 flex w-full mt-auto p-lg bg-white border-t border-light-grey">
          <button
            onClick={clearAllFilters}
            className="button button--text flex-shrink-0 text-sm text-default"
          >
            clear all
          </button>
          <button
            className="button w-[252px] ml-auto"
            onClick={() => {
              toggleFilterList();
              setFiltersOpen(false);
            }}
          >
            view results
          </button>
        </div>
      </div>
    </>
  );
};

export default StoreFilters;
