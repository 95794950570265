import { Coordinates } from "custom-types/Coordinates";

import { CurrentStatus } from "./Dispensary";

export type CardConfigurations = {
  adEnabled: boolean;
  ctaEnabled: boolean;
  dealEnabled: boolean;
  deliveryEnabled: boolean;
  imageEnabled: boolean;
  inStoreCartEnabled: boolean;
  leaflyListEnabled: boolean;
  logoEnabled: boolean;
  newFlagEnabled: boolean;
  offerEnabled: boolean;
  pickupEnabled: boolean;
  pickupReadinessEnabled: boolean;
  preorderEnabled: boolean;
};

export enum MapMarkerDisplayTypeEnum {
  ProminentImageContainer = "prominent-image-container",
  ImageContainer = "image-container",
  ProminentIcon = "prominent-icon",
  Icon = "icon",
}

export type MapMarker = {
  displayType: MapMarkerDisplayTypeEnum;
  lat: number;
  lon: number;
  imageUrl: string;
};

export type Address = {
  address1: string | null;
  address2: string | null;
  city: string;
  country: string;
  lat: number;
  lon: number;
  state: string;
  zip: string;
};

export type Store = {
  id: number;
  address: Address;
  configurations: CardConfigurations;
  dealId: number | null;
  dealLabel: string;
  dealTitle: string;
  featureTier: string;
  fulfillmentDetails: string | null;
  imageUrl: string | null;
  logoUrl: string | null;
  mainTag: string;
  mapMarkers: MapMarker[];
  merchandisingCampaignId: number | null;
  name: string;
  openStatus: CurrentStatus;
  path: string;
  reviewCount: number;
  reviewRating: number;
  slug: string;
  timeZone: string;
  distanceMi: number;
};

export type SwipeState = {
  dragging: boolean;
  position: number | null;
};

export type StoreMetadata = {
  totalCount: number;
};

export type StoreSeo = {
  indexEnabled: boolean;
  headline: string | null;
  pageTitle: string | null;
  description: string | null;
  canonicalUrl: string | null;
};

export type MapData = {
  center?: Coordinates;
  zoom?: number;
  bounds?: google.maps.LatLngBoundsLiteral;
};
