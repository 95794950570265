import { GetServerSidePropsContext } from "next";
import { ParsedUrlQuery } from "querystring";

import { CountryCode } from "../../custom-types/CountryCode";
import { Location } from "../../custom-types/Location";
import getFinderLocation from "../finder/contextData/getFinderLocation";
import { getCountryCodeHeader } from "../requestContext";

export type StoreLocatorLocation = {
  finderLocation: Location | undefined;
  isLocationPage: boolean;
};

export const getLocation = async (
  context: GetServerSidePropsContext,
): Promise<StoreLocatorLocation> => {
  const { query } = context;
  const city = getCity(query);
  const state = getState(query);
  const countryCode = getCountryCodeHeader(context.req.headers) as CountryCode;

  // TODO: We should move getFinderLocation to store locator bucket and wrap in tests
  // JIRA Ticket: https://leafly.atlassian.net/browse/NL-1997
  return await getFinderLocation(city, state, countryCode, context);
};

function getCity(query: ParsedUrlQuery) {
  const city = query?.location?.[1];
  const state = query?.location?.[0];
  return state === "locations" ? undefined : city;
}

export function getState(query: ParsedUrlQuery) {
  const state = query?.location?.[0];
  return state === "locations" ? undefined : state;
}
