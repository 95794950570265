import { FinderBreadcrumbLocation } from "custom-types/Finder";
import { Location } from "custom-types/Location";
import { getLocationRoute } from "utils/locationUtils";

import Breadcrumb from "components/Breadcrumb";
import Breadcrumbs from "components/Breadcrumbs";

const StoreBreadcrumbs = ({
  isLocationPage,
  pageLocation,
}: {
  isLocationPage: boolean;
  pageLocation: Location;
}) => {
  const generateUrl = (type: string): string => {
    switch (type) {
      case "home":
        return "/";
      case "siteMap":
        return "/dispensaries/locations";
      case "activeView":
        return "/dispensaries";
      case "state":
        return `${getLocationRoute("/dispensaries", {
          state: pageLocation.state,
        })}`;
      case "city":
        return `${getLocationRoute("/dispensaries", {
          city: pageLocation.city,
          slug: pageLocation.geoSlug,
          state: pageLocation.state,
        })}`;
      case "zip":
        return `${getLocationRoute(
          "/dispensaries",
          pageLocation as FinderBreadcrumbLocation,
        )}`;
      default:
        return "";
    }
  };

  const arrayOfLinks: { label: string; url?: string }[] = [
    { label: "Dispensaries", url: generateUrl("activeView") },
    {
      label: pageLocation.countryCode === "CA" ? "Canada" : "United States",
      url: generateUrl("siteMap"),
    },
  ];

  if (pageLocation.state) {
    arrayOfLinks.push({
      label: pageLocation.state,
      url: generateUrl("state"),
    });
  }
  if (pageLocation.city) {
    arrayOfLinks.push({
      label: pageLocation.city,
      url: generateUrl("city"),
    });
  }

  if (pageLocation.isUserLocation) {
    arrayOfLinks.push({ label: "My location" });
  } else if (pageLocation.zip || pageLocation?.street?.name) {
    const streetNumber = pageLocation?.street?.number || "";
    const streetName = pageLocation?.street?.name || "";
    const zip = pageLocation.zip || "";
    const shouldHaveComma =
      pageLocation?.street?.number &&
      pageLocation?.street?.name &&
      pageLocation.zip
        ? ", "
        : "";
    arrayOfLinks.push({
      label: `${streetNumber} ${streetName}${shouldHaveComma}${zip}`,
    });
  }

  return (
    <div className="px-lg mb-xxl">
      <Breadcrumbs orderedListClassNames="!m-0" includeSchema={isLocationPage}>
        {arrayOfLinks.map(({ url, label }, idx) => (
          <Breadcrumb
            key={label}
            href={url}
            currentLocation={idx === arrayOfLinks.length - 1}
          >
            {label}
          </Breadcrumb>
        ))}
      </Breadcrumbs>
    </div>
  );
};

export default StoreBreadcrumbs;
