import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";

import getDispensariesV3 from "api/requests/consumerApi/getDispensariesV3";
import { Action, Category } from "constants/events";
import { DispensaryV3 } from "custom-types/DispensariesV3";
import { Location } from "custom-types/Location";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import useWindowSize from "hooks/useWindowSize";
import { useSelector } from "redux/hooks";
import { getUserId } from "redux/selectors/user";
import { determineOrderMethod } from "utils/determineOrderMethod";
import { getRoundedDistance } from "utils/distance";
import { getMerchId } from "utils/eventTracking";
import formatImgixUrl from "utils/image/formatImgixUrl";
import { getLocationRoute } from "utils/locationUtils";

import Carousel from "components/botanic/Carousel";
import DispensaryCardPlaceholder from "components/botanic/DispensaryCard/DispensaryCardPlaceholder";
import PickupReadinessDispensaryCard from "components/PickupReadinessDispensaryCard";
import TrackImpression from "components/TrackImpression";

const CBDStoresCarousel = ({
  setCbdStoreCount,
  pageLocation,
}: {
  setCbdStoreCount: (cbdStoreCount: number) => void;
  pageLocation: Location;
}) => {
  const countryCode = useDomainCountryCode();
  const userId = useSelector(getUserId);

  const { publishEvent } = useEventTracker();

  const { isMobile } = useWindowSize();

  const title = `Shop ${isMobile ? "Hemp/" : ""}CBD stores near you`;

  const trackCardImpression = (slot: number, dispensary: DispensaryV3) => {
    const platinumPlacement = !!dispensary.merchandisedCampaigns?.find(
      (campaign) => campaign.unitName === "platinumplacement",
    );

    const merchandisingCampaignId = getMerchId(dispensary, "platinumplacement");

    publishEvent({
      action: Action.impression,
      category: Category.dispensaryCard,
      dispensaryId: dispensary.id,
      label: platinumPlacement
        ? `cbd carousel card platinum placement - ${slot + 1}`
        : `cbd carousel card - ${slot + 1}`,
      merchandisingCampaignId,
    });
  };

  const NotFoundIcon = dynamic<{ width: number }>(
    () => import("components/Icons/not_found.svg"),
  );

  const { query } = useRouter();
  const isDelivery = query?.filter?.includes("delivery");

  const { city, radius, state, zip, coordinates, formattedLocation } =
    pageLocation;

  const customDimensions = {
    searchCity: city,
    searchRadius: radius,
    searchState: state,
    searchZip: zip,
  };

  const [cbdStoresLoaded, setCBDStoresLoaded] = useState(false);
  const [cbdStores, setCBDStores] = useState<DispensaryV3[]>([]);

  useEffect(() => {
    setCBDStoresLoaded(false);

    if (coordinates?.lat && coordinates.lon) {
      const flags = ["cbd-store"];

      if (isDelivery) {
        flags.push("delivery");
      }

      getDispensariesV3({
        flags,
        lat: coordinates.lat,
        lon: coordinates.lon,
        radius,
        take: 7,
      }).then(({ stores, sponsoredStores }) => {
        sponsoredStores.forEach((_store: DispensaryV3, i: number) => {
          sponsoredStores[i].isAd = true;
        });

        const dispensariesArray = sponsoredStores.concat(stores).splice(0, 7);

        dispensariesArray.forEach((dispensary: DispensaryV3, index: number) => {
          if (dispensary.logoUrl) {
            dispensariesArray[index].logoUrl = formatImgixUrl(
              dispensary.logoUrl,
            );
          }
        });

        setCBDStores(dispensariesArray);
        setCBDStoresLoaded(true);
        setCbdStoreCount(dispensariesArray.length);
      });
    }
  }, [isDelivery]);

  if (!cbdStoresLoaded || cbdStores.length === 0) {
    return null;
  }

  return (
    <TrackImpression
      category="Finder results"
      label="No locations nearby"
      className="finder__no-results mb-sm"
      data-testid="finder-no-results"
      customDimensions={customDimensions}
    >
      <div>
        <div className="border border-light-grey rounded flex flex-col items-center p-4 mt-4">
          <NotFoundIcon width={60} />
          <h3 className="mt-2 font-bold">Looking for Hemp or CBD?</h3>
          <div className="mt-4 text-center">
            We don&apos;t see many THC stores near you, but we found these
            Hemp/CBD shops.
          </div>
        </div>
        <div className="mt-4">
          <Carousel
            cardsPerSlide={4}
            preventCollapse
            title={title}
            subtitle={
              city && state && formattedLocation ? (
                <>
                  Showing you CBD stores near{" "}
                  <Link
                    className="font-bold cursor-pointer"
                    href={getLocationRoute("/cbd-stores", {
                      city,
                      state,
                    })}
                  >
                    {formattedLocation}
                  </Link>
                </>
              ) : null
            }
            seeMoreText="see all stores"
            seeMoreHref="/cbd-stores"
            id="cbd-stores-section"
            seeMoreOnClick={() => {
              publishEvent({
                action: Action.click,
                category: Category.carousel,
                label: "CBD stores carousel show all",
              });
            }}
          >
            {cbdStores.map(
              (store: DispensaryV3 | Record<string, never>, slot: number) => {
                if (store.name) {
                  const eventLabel = determineOrderMethod(
                    !!store.flags.includes("pickup"),
                    !!store.flags.includes("delivery"),
                    store.tier,
                    store.flags,
                  );
                  const platinumPlacement = store.merchandisedCampaigns?.find(
                    (campaign) => campaign.unitName === "platinumplacement",
                  );
                  const merchandisingCampaignId = platinumPlacement
                    ? platinumPlacement.id
                    : null;
                  return (
                    <TrackImpression
                      className="h-full"
                      trackingFunction={() =>
                        trackCardImpression(slot, store as DispensaryV3)
                      }
                      key={`dispensary-${slot}`}
                    >
                      <PickupReadinessDispensaryCard
                        key={store.name}
                        preserveHeight
                        currentStatus={store.currentStatuses?.store}
                        deliveryEnabled={!!store.flags.includes("delivery")}
                        distanceMi={store.distanceMi}
                        dispensaryId={store.id}
                        inStoreCartEnabled={store.inStoreCartEnabled}
                        isAd={store.isAd}
                        isNewDispensary={store.flags.includes("new_dispensary")}
                        logoUrl={store.logoUrl}
                        name={store.name}
                        pickupEnabled={!!store.flags.includes("pickup")}
                        preorderConfigured={
                          !!store.flags.includes("preorder_configured")
                        }
                        onClick={() => {
                          publishEvent({
                            action: Action.click,
                            category: Category.dispensaryCard,
                            dispensaryDistance: getRoundedDistance(
                              store.distanceMi,
                              countryCode,
                            ),
                            dispensaryId: store.id,
                            label: `slot #${slot + 1}: ${eventLabel}`,
                            merchandisingCampaignId:
                              merchandisingCampaignId || undefined,
                            userId,
                          });
                        }}
                        rating={store.starRating}
                        reviewCount={store.reviewCount}
                        scheduleType="store"
                        slug={store.slug}
                        tags={store.flags}
                        timeZone={store.timeZone}
                      />
                    </TrackImpression>
                  );
                } else {
                  return (
                    <DispensaryCardPlaceholder
                      id={`store-card-placeholder-${slot}`}
                      key={slot}
                      hideCTA
                    />
                  );
                }
              },
            )}
          </Carousel>
        </div>
      </div>
    </TrackImpression>
  );
};

export default CBDStoresCarousel;
