import React from "react";

const DoctorAdLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="93"
    height="12"
    viewBox="0 0 80 11"
  >
    <path
      style={{
        fill: "#36dc90",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M5.25 10.73V8.848H0V6.949h3.898L.375 3.461 1.73 2.117l3.52 3.485V.164h1.922v5.45l3.52-3.489 1.355 1.344-3.508 3.48h3.89v1.899H7.173v1.882Zm0 0"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "evenodd",
        stroke: "none",
      }}
      d="M50.188 1.973a5.205 5.205 0 0 0-1.41-.172c-1.063 0-1.505.332-1.508 1.144h2.96v1.688h-2.96v6.105h-1.891V4.633h-1.89V2.945h1.89v-.039C45.379 1.031 46.586 0 48.777 0c.504 0 1.262.066 1.758.25l.082.035L50.293 2ZM21.37.16h1.934v10.578H21.37Zm3.352 6.688c0-2.344 1.867-4.047 4.445-4.047h.004c2.437 0 4.14 1.62 4.14 3.945v.715h-6.718c.265 1.066 1.261 1.746 2.574 1.746 1.152 0 2.059-.55 2.418-1.488l.047-.114 1.562.836-.035.09c-.457 1.18-1.87 2.367-3.992 2.367-2.617 0-4.445-1.668-4.445-4.05Zm4.355-2.387c-1.152 0-2.062.562-2.402 1.488h4.734c-.187-.742-1.09-1.488-2.332-1.488Zm13.293 4.726c-.07-.054-.101-.152-.101-.32V5.68c0-1.86-1.325-2.88-3.743-2.88-1.898 0-3.558.895-4.035 2.177l-.035.093 1.61.723.042-.106c.266-.695 1.266-1.203 2.364-1.203 1.203 0 1.902.41 1.925 1.125l-3.171.38c-1.922.234-2.985 1.105-2.985 2.44 0 1.497 1.266 2.462 3.227 2.462 1.265 0 2.445-.403 3.195-1.098.242.57.895 1.3 2.637.934l.09-.016V9.152l-.133.032c-.473.101-.766.105-.89 0Zm-1.973-2V7.7c0 .977-1.445 1.582-2.789 1.582-.554 0-1.48-.11-1.48-.86 0-.534.379-.769 1.406-.89Zm19.418.977-1.855-5.219h-1.613l-1.844 5.22-1.914-5.22h-2.047l3.047 7.793h1.637l1.886-5.097 1.899 5.097h1.636l3.036-7.793H61.69Zm4.047-1.316c0-2.344 1.867-4.047 4.442-4.047 2.441 0 4.144 1.62 4.144 3.945v.715h-6.715c.266 1.066 1.258 1.746 2.57 1.746 1.157 0 2.06-.55 2.423-1.488l.043-.114 1.566.836-.035.09c-.457 1.18-1.871 2.367-3.996 2.367-2.614 0-4.442-1.668-4.442-4.05Zm4.36-2.387c-1.157 0-2.063.562-2.403 1.488h4.735c-.188-.742-1.09-1.488-2.332-1.488Zm7.562-4.3h-1.93v10.577h1.93Zm1.88 0h1.929v10.577h-1.93Zm0 0"
    />
  </svg>
);

export default DoctorAdLogo;
