import { useRef, useState } from "react";
import { trackEvent } from "@leafly-com/web-utils";

import { Location } from "custom-types/Location";
import { updateLocation } from "redux/action-creators/location";
import { useDispatch, useSelector } from "redux/hooks";
import { getLocationState } from "redux/selectors/location";
import getCurrentGeolocationPosition from "utils/getCurrentGeolocationPosition";
import { getLocationForCookie, getLocationSlug } from "utils/locationUtils";

import DeliveryValidationModal from "components/botanic/DeliveryValidationModal";
import DeliveryValidationButton from "components/botanic/DeliveryValidationModal/DeliveryValidationButton";

const DeliveryValidation = ({
  pageLocation,
  onSuccessfulResult,
}: {
  pageLocation: Location;
  onSuccessfulResult: () => void;
}) => {
  const userLocation = useSelector(getLocationState);
  const [isVisible, setIsVisible] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleUpdateDeliveryLocation = async (newAddress: Location) => {
    const location = getLocationForCookie(newAddress, true);
    const newLocation = {
      ...newAddress,
      ...location,
      slug: getLocationSlug(location),
    };
    await updateLocation(dispatch, newLocation);

    if (onSuccessfulResult && (newLocation?.state || newLocation?.city)) {
      onSuccessfulResult();
    }
  };

  const handleSeeDispensaries = (newAddress: Location) => {
    if (newAddress && newAddress !== userLocation) {
      handleUpdateDeliveryLocation(newAddress);
    }

    trackEvent(
      "Finder - Delivery Address Validation Modal",
      "Click",
      "Confirmed Delivery Address",
    );

    setIsVisible(false);
  };

  const dispatch = useDispatch();
  const handleSecondaryAction = () => {
    trackEvent(
      "Finder - Delivery Validation Geolocate",
      "Detect Delivery Area Location",
    );

    return getCurrentGeolocationPosition(
      dispatch,
      (location: Location) => {
        updateLocation(dispatch, location);
        setIsVisible(false);
      },
      { reloadPageOnSuccess: true },
    );
  };

  const handleClick = () => {
    trackEvent("Delivery", "Click", "Address validation: Finder");
    setIsVisible(true);
  };

  return (
    <>
      <div className="pb-xs pt-lg" data-testid="delivery-validation-btn">
        <DeliveryValidationButton
          address={
            userLocation?.formattedAddress || pageLocation?.formattedAddress
          }
          handleClick={handleClick}
          ref={buttonRef}
        />
      </div>
      <DeliveryValidationModal
        data-testid="delivery-validation-modal"
        defaultAddress={userLocation || pageLocation}
        handlePrimaryAction={handleSeeDispensaries}
        handleSecondaryAction={handleSecondaryAction}
        onDismissed={() => setIsVisible(false)}
        primaryActionLabel="see dispensaries"
        returnFocusRef={buttonRef}
        secondaryActionLabel="use current location"
        showModal={isVisible}
        successMessage=""
        width={500}
      />
    </>
  );
};

export default DeliveryValidation;
