import { useEffect } from "react";
import { trackEvent } from "@leafly-com/web-utils";

import OpenInNewIcon from "components/Icons/open_in_new.svg";

import DoctorAdLogo from "./DoctorAdLogo";

const DoctorAd = () => {
  useEffect(() => {
    trackEvent("Leafwell Banner", "impression", "doctor finder");
  }, []);

  return (
    <a
      href="https://leafwell.com/medical-card?utm_source=leafly&utm_medium=doctorad"
      rel="noreferrer"
      target="_blank"
      onClick={() => trackEvent("Leafwell Banner", "click", "doctor finder")}
    >
      <div className="doctorad bg-default rounded p-lg text-white flex flex-col justify-between cursor-pointer my-lg">
        <h3 className="mb-sm">
          Save 20% on your medical marijuana card with Leafwell
        </h3>
        <div className="mb-lg">
          Code: <span className="font-bold"> LEAFLY20</span>
        </div>
        <div className="flex flex-row items-center justify-between">
          <div className="font-bold text-xs flex flex-row items-center ">
            At <span className="underline mx-xs ">leafwell.com</span>
            <OpenInNewIcon width="14" height="14" />
          </div>
          <DoctorAdLogo />
        </div>
      </div>
    </a>
  );
};

export default DoctorAd;
