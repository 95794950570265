import React, { useCallback, useContext, useMemo } from "react";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import classnames from "classnames";

import { Action, Category } from "constants/events";
import StoreLocatorContext from "context/StoreLocatorContext";
import {
  MapMarker,
  MapMarkerDisplayTypeEnum,
  Store,
} from "custom-types/StoreLocator";
import { useEventTracker } from "hooks/useEventTracker";

import DynamicMarker from "./DynamicMarker";
import MapMarkerImage from "./MapMarkerImage";

const markerLayers = {
  [MapMarkerDisplayTypeEnum.Icon]: 100,
  [MapMarkerDisplayTypeEnum.ProminentIcon]: 200,
  [MapMarkerDisplayTypeEnum.ImageContainer]: 300,
  [MapMarkerDisplayTypeEnum.ProminentImageContainer]: 400,
};

const markerSizes = {
  [MapMarkerDisplayTypeEnum.Icon]: "sm",
  [MapMarkerDisplayTypeEnum.ProminentIcon]: "md",
  [MapMarkerDisplayTypeEnum.ImageContainer]: "lg",
  [MapMarkerDisplayTypeEnum.ProminentImageContainer]: "xl",
};

const MAX_Z_INDEX = 1005;

type MapMarkerProps = {
  store: Store;
  mapMarker: MapMarker;
};

const StoreLocatorMapMarker = ({ store, mapMarker }: MapMarkerProps) => {
  const {
    hoveredStore,
    setHoveredStore,
    clearHoveredStore,
    handleMarkerClick,
    selectedStore,
  } = useContext(StoreLocatorContext);
  const { publishEvent } = useEventTracker();

  const isHovered = store.id === hoveredStore;

  const classes = classnames(
    "flex absolute items-center justify-center transform",
    "-translate-x-2/4 bottom-0 h-auto finder-map-marker",
    `marker-size--${markerSizes[mapMarker.displayType]}`,
    {
      "hovered-store-animation": isHovered,
    },
  );

  const zIndexes = useMemo(() => {
    return {
      hovered:
        MAX_Z_INDEX +
        Number(
          mapMarker.displayType ===
            MapMarkerDisplayTypeEnum.ProminentImageContainer,
        ),
      notHovered:
        Math.floor(Math.random() * 100) + markerLayers[mapMarker.displayType],
    };
  }, []);

  const handleClickEvent = useCallback(() => {
    if (selectedStore?.id === store.id) {
      const eventCategory =
        mapMarker.displayType ===
        MapMarkerDisplayTypeEnum.ProminentImageContainer
          ? Category.mapMarkerPlatinum
          : Category.mapMarkerCustom;

      publishEvent({
        action: Action.click,
        category: eventCategory,
        dealId: store.dealId || undefined,
        dispensaryId: store.id,
        label: store.name,
        merchandisingCampaignId: store.merchandisingCampaignId || undefined,
      });
    }
  }, [selectedStore]);

  return (
    <AdvancedMarker
      position={{ lat: mapMarker.lat, lng: mapMarker.lon }}
      onClick={() => handleMarkerClick(store, handleClickEvent, mapMarker)}
      onMouseEnter={() => setHoveredStore(store.id)}
      onMouseLeave={clearHoveredStore}
      zIndex={zIndexes[isHovered ? "hovered" : "notHovered"]}
    >
      <div className={classes} title={`click to highlight ${store.name}`}>
        <MapMarkerImage
          logoImage={mapMarker.imageUrl || ""}
          alt={store.name}
          displayType={mapMarker.displayType}
        />
        <DynamicMarker
          displayType={mapMarker.displayType}
          isHovered={isHovered}
        />
      </div>
      <style jsx global>{`
        .finder-map-marker {
          --finder-map-marker-xl: 72px;
          --finder-map-marker-lg: 50px;
          --finder-map-marker-md: 32px;
          --finder-map-marker-sm: 12px;
          --finder-map-marker-hover-grow: 8px;
        }
        .finder-map-marker > * {
          pointer-events: none;
          touch-action: none;
        }
        .marker-size--xl {
          width: var(--finder-map-marker-xl);
        }
        .marker-size--xl .map-marker__image {
          width: calc(var(--finder-map-marker-xl) - 12px);
          height: calc(var(--finder-map-marker-xl) - 12px);
          top: 4px;
          left: 5px;
        }
        .marker-size--lg {
          width: var(--finder-map-marker-lg);
        }
        .marker-size--lg .map-marker__image {
          width: calc(var(--finder-map-marker-lg) - 6px);
          height: calc(var(--finder-map-marker-lg) - 6px);
        }
        .marker-size--md {
          width: var(--finder-map-marker-md);
        }
        .marker-size--sm {
          width: var(--finder-map-marker-sm);
        }
        .hovered-store-animation svg {
          overflow: visible !important;
        }
        .hovered-store-animation {
          transition:
            height 0.25s ease,
            width 0.25s ease;
        }
        .hovered-store-animation .map-marker__image {
          transition:
            height 0.25s ease,
            width 0.25s ease;
        }
        .hovered-store-animation.marker-size--xl .map-marker__image {
          width: calc(
            var(--finder-map-marker-xl) + var(--finder-map-marker-hover-grow) -
              12px
          );
          height: auto;
        }
        .hovered-store-animation.marker-size--lg .map-marker__image {
          width: calc(
            var(--finder-map-marker-lg) + var(--finder-map-marker-hover-grow) -
              6px
          );
          height: calc(
            var(--finder-map-marker-lg) + var(--finder-map-marker-hover-grow) -
              6px
          );
        }
        .hovered-store-animation.marker-size--xl {
          width: calc(
            var(--finder-map-marker-xl) + var(--finder-map-marker-hover-grow)
          );
          height: auto;
        }
        .hovered-store-animation.marker-size--lg {
          width: calc(
            var(--finder-map-marker-lg) + var(--finder-map-marker-hover-grow)
          );
          height: auto;
        }
        .hovered-store-animation.marker-size--md {
          width: calc(
            var(--finder-map-marker-md) + var(--finder-map-marker-hover-grow)
          );
          height: auto;
        }
        .hovered-store-animation.marker-size--sm {
          width: calc(
            var(--finder-map-marker-sm) + var(--finder-map-marker-hover-grow)
          );
          height: auto;
        }
      `}</style>
    </AdvancedMarker>
  );
};

export default StoreLocatorMapMarker;
