import React, { useState } from "react";

import { Location } from "custom-types/Location";
import { RetailType } from "custom-types/Store";

import { useFilterQueryParams } from "components/MenuFilters/v2/hooks";

import { StoreLocatorResultsResponse } from "../../../api/requests/consumerApi/getStoreLocatorResults";
import { FAQ } from "../../../custom-types/Directus";
import ShopNoResults from "../../ShopNoResults";
import DispensariesHeader from "../DispensariesHeader";
import StoreFilters from "../StoreFilters";
import StoreListings from "../StoreListings";

const StoreMenuContainer = ({
  children,
  storeLocatorResults: {
    data: { organicStores, sponsoredStores },
    metadata: { availableFilters, availableSorts, seo, totalCount },
  },
  toggleFilterList,
  faqs,
  shallowRouting = false,
  retailType,
  pageLocation,
  showDeliveryValidation,
  showMapButton,
  showingCBDTreatment,
}: {
  children: React.ReactNode;
  storeLocatorResults: StoreLocatorResultsResponse;
  toggleFilterList: (open?: boolean) => void;
  faqs: FAQ | null;
  shallowRouting?: boolean;
  retailType: RetailType;
  pageLocation: Location;
  showDeliveryValidation: boolean;
  showMapButton: boolean;
  showingCBDTreatment: boolean;
}) => {
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const {
    actions: { clearAllFilters },
  } = useFilterQueryParams(shallowRouting);

  const stores = [...sponsoredStores, ...organicStores];

  return (
    <>
      <DispensariesHeader
        filtersOpen={filtersOpen}
        seo={seo}
        seoOverride={faqs}
        pageLocation={pageLocation}
        showDeliveryValidation={showDeliveryValidation}
      />
      <StoreFilters
        availableFilters={availableFilters}
        toggleFilterList={toggleFilterList}
        filtersOpen={filtersOpen}
        setFiltersOpen={setFiltersOpen}
        shallowRouting={shallowRouting}
        availableSorts={availableSorts}
        totalCount={totalCount}
        showMapButton={showMapButton}
        retailType={retailType}
      >
        {(stores.length || !showingCBDTreatment) && (
          <div className="px-lg py-lg">
            {!stores.length && !showingCBDTreatment && (
              // TODO: update onButtonClick to reset filter state
              <ShopNoResults
                action={clearAllFilters}
                subtitleText="Try selecting fewer filter options or zoom out on the map for more results"
                primaryLinkText="clear filters"
                titleText="No results found"
              />
            )}
            <StoreListings
              retailType={retailType}
              sponsoredStores={sponsoredStores}
              stores={organicStores}
            />
          </div>
        )}
        {children}
      </StoreFilters>
    </>
  );
};

export default StoreMenuContainer;
