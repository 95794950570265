import React, { useMemo, useState } from "react";
import { useRouter } from "next/router";

import { City } from "custom-types/CitiesNearMe";
import { CopyBlock as CopyBlockType, FAQ } from "custom-types/Directus";
import { Location } from "custom-types/Location";
import { Query } from "custom-types/Query";
import { RetailType } from "custom-types/Store";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useSelector } from "redux/hooks";
import { getShowBanner } from "redux/selectors/header";
import isCanada from "utils/isCanada";
import parseNextRouterAsPath from "utils/parseNextRouterAsPath";

import CopyBlock from "components/CopyBlock/CopyBlock";
import ContentLayout from "components/dispensaries/ContentSection/ContentLayout";
import RecentlyViewedCarouselWithData from "components/dispensaries/RecentlyViewedCarousel";
import RetailCategoryNavigation from "components/dispensaries/RetailCategoryNavigation";
import StoreBreadcrumbs from "components/dispensaries/StoreBreadcrumbs";
import StoreMenuContainer from "components/dispensaries/StoreMenuContainer/StoreMenuContainer";
import FAQs from "components/FAQ";
import Footer from "components/Finder/Footer";

import { StoreLocatorResultsResponse } from "../../../api/requests/consumerApi/getStoreLocatorResults";
import PageNavigation from "../../PageNavigationV2";
import CBDStoresCarousel from "../CBDStoresCarousel/CBDStoresCarousel";
import CitiesNearby from "../CitiesNearby/CitiesNearby";
import { ContentLayoutProps } from "./ContentLayout/ContentLayout";

interface ContentSectionProps {
  cities: City[];
  copyBlockData: CopyBlockType | null;
  faqs: FAQ | null;
  isLocationPage: boolean;
  page: number;
  pageLocation: Location;
  retailType: RetailType;
  storeLocatorResults: StoreLocatorResultsResponse;
  showDeliveryValidation: boolean;
  totalPages: number;
}

const ContentSection = ({
  cities,
  copyBlockData,
  faqs,
  isLocationPage,
  page,
  pageLocation,
  retailType,
  showDeliveryValidation,
  storeLocatorResults,
  totalPages,
}: ContentSectionProps) => {
  const showBanner = useSelector(getShowBanner);
  const product = {
    "cbd-store": "hemp / CBD",
    clinic: "MMJ Doctors",
    dispensary: "weed",
    undefined: "weed",
  };

  const countryCode = useDomainCountryCode();
  const { totalCount } = storeLocatorResults.metadata;
  const isCa = isCanada(countryCode);
  const { pathname, asPath } = useRouter();
  const { query } = parseNextRouterAsPath(asPath);
  const { ownership, "strain-names": strainNames } =
    (query.filter as unknown as Query) || {};

  const allowCBDStoreCarousel = useMemo(
    () =>
      totalCount <= 5 &&
      !isCa &&
      !pathname.includes("doctors") &&
      !pathname.includes("cbd-stores") &&
      !ownership &&
      !strainNames,
    [totalCount, countryCode, pathname, query],
  );
  const [cbdStoreCount, setCbdStoreCount] = useState(0);

  return (
    <ContentLayout showBanner={showBanner}>
      {({ toggleFilterList, showMapButton }: ContentLayoutProps) => (
        <div className="flex flex-col h-full bg-white gap-md md:gap-0">
          <div
            className="pt-[10px] md:hidden flex justify-center items-center bg-white"
            data-testid="listing-wrapper-handle"
          >
            <span className="bg-light-grey rounded-full w-[64px] h-[6px]" />
          </div>
          <StoreMenuContainer
            showMapButton={showMapButton}
            storeLocatorResults={storeLocatorResults}
            toggleFilterList={toggleFilterList}
            faqs={faqs}
            retailType={retailType}
            pageLocation={pageLocation}
            showDeliveryValidation={showDeliveryValidation}
            showingCBDTreatment={cbdStoreCount > 0}
          >
            <div className="[&>*]:px-lg">
              <PageNavigation currentPage={page} totalPages={totalPages} />
              <RecentlyViewedCarouselWithData />
              {allowCBDStoreCarousel && (
                <CBDStoresCarousel
                  setCbdStoreCount={setCbdStoreCount}
                  pageLocation={pageLocation}
                />
              )}
              <RetailCategoryNavigation retailType={retailType} />
              <CitiesNearby
                cities={cities}
                heading={`Find ${
                  product[retailType as keyof typeof product]
                } in a city near you`}
                retailType={retailType}
              />
              <hr className="border-light-grey my-xxl" />
              {copyBlockData?.copy_block?.length ? (
                <CopyBlock data={copyBlockData} />
              ) : (
                <></>
              )}
            </div>
            <div className="bg-green-20 my-xxl">
              <FAQs
                data={faqs?.faqs}
                initialShowPanel={true}
                className="py-xxl md:px-lg"
              />
            </div>
            <StoreBreadcrumbs
              isLocationPage={isLocationPage}
              pageLocation={pageLocation}
            />
            <Footer />
          </StoreMenuContainer>
        </div>
      )}
    </ContentLayout>
  );
};

export default ContentSection;
