import React from "react";

import { getTypeTitle } from "constants/storeLocatorConfig";
import { City } from "custom-types/CitiesNearMe";
import { RetailType } from "custom-types/Store";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import getStoreLocatorRoute from "utils/storeLocator/getStoreLocatorRoute";

import LinkButton from "../LinkButton";

type Props = {
  data: City;
  retailType: RetailType;
  extraClasses?: string;
};

const CityCard = ({
  data: { city, count, distance, distanceUnit, path, state },
  extraClasses,
  retailType,
}: Props) => {
  const countryCode = useDomainCountryCode();

  const retailerPluralization = getTypeTitle(retailType, count, countryCode);
  const storeLocatorRoute = getStoreLocatorRoute(retailType, countryCode);

  return (
    <LinkButton
      link={`${storeLocatorRoute}${path}`}
      header={`${city}, ${state}`}
      content={
        <CityContent
          distance={`${distance.toFixed(1)} ${distanceUnit}`}
          count={`${count} ${retailerPluralization}`}
        />
      }
      className={`block h-full ${extraClasses}`}
      trackingInfo={{
        category: "Cities Near You",
        label: `Online Ordering Slot: ${city}, ${state}`,
      }}
    />
  );
};

const CityContent = ({
  distance,
  count,
}: {
  distance: string;
  count: string;
}) => (
  <>
    <span>{distance}</span>
    <br />
    <span>{count}</span>
  </>
);

export default CityCard;
